<template>
  <div>
    <div class="explain pd30" v-html="explain"></div>
    <div class="comment mt20 pd20">
      <div id="SOHUCS" sid="-2"></div>
    </div>
  </div>
</template>

<script>
import explain from "@/api/explain";
import init from '@/utils'
import initData from '@/utils/comment'
export default {
  data() {
    return {
      explain: "",
    };
  },
  created() {
    this.getExplain();
  },
  mounted() {
    init();
    initData()
  },
  destroyed() {
    window.changyan = undefined;
    window.cyan = undefined;
  },
  methods: {
    async getExplain() {
      const res = await explain.getExplain();
      this.explain = res.data[0].text;
    },
  },
};
</script>

<style lang="less">
.explain {
  background-color: #e4d5d8;
  border-radius: 5px;
  text-align: center;
}
</style>